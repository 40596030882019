/*
 * (c) Copyright 2023 Thalia Bücher GmbH
 *
 * Created on 15.05.2023
 *
 * This file contains unpublished, proprietary trade secret information of
 * Thalia Bücher GmbH. Use, transcription, duplication and
 * modification are strictly prohibited without prior written consent of
 * Thalia Bücher GmbH.
 */

export {
    cookieWertAuslesen
}

/**
 * @param {string} name
 * @returns {string|boolean}
 */
function cookieWertAuslesen(name) {
    const cookieArray = document.cookie.split(";");
    const cookiePair = cookieArray.find(pair => {
        const cookieEqualSignPosition = pair.indexOf("=");
        const cookieName = pair.substring(0, cookieEqualSignPosition).trim();
        return cookieName === name;
    })
    if ((typeof cookiePair === "undefined") || (cookiePair.indexOf("=") === -1)) return false;
    const cookieEqualSignPosition = cookiePair.indexOf("=");
    return cookiePair.substring(cookieEqualSignPosition + 1);
}
