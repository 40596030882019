/*
 * (c) Copyright 2023 Thalia Bücher GmbH
 *
 * Created on 16.05.2023
 *
 * This file contains unpublished, proprietary trade secret information of
 * Thalia Bücher GmbH. Use, transcription, duplication and
 * modification are strictly prohibited without prior written consent of
 * Thalia Bücher GmbH.
 */

import {
    ABHOLFILIALE_COOKIE_NAME,
    AMPEL_STATUS_SET_ID,
    BESTANDANZEIGE_CUSTOM_EVENT,
    BESTANDANZEIGE_REMOVE_CUSTOM_EVENT,
    CUSTOM_ELEMENT_NAME,
    SUCHE_ABHOLFILIALE_COOKIE_NAME
} from "../buchhandlung-bestandanzeige/config";
import {cookieWertAuslesen} from "../../assets/js/utils";

const mutationObserver = startMutationObserver();
updateBestandanzeige();

function startMutationObserver() {
    const targetNode = document.body;
    const config = { attributes: false, childList: true, subtree: true };
    const observer = new MutationObserver(mutationCallback);
    observer.observe(targetNode, config);
    return observer;
}

function mutationCallback(mutationList, observer) {
    updateBestandanzeige();
}

export async function updateBestandanzeige() {
    try {
        const abholfiliale = bestimmeAbholfiliale();
        // Wenn es keine Abholfiliale gibt, dann gibt es auch keine Bestände
        if (!abholfiliale) {
            sendeBuchhandlungBestandanzeigeRemoveEvent();
            return;
        }

        const bestandanzeigen = document.querySelectorAll(`${CUSTOM_ELEMENT_NAME}:not([${AMPEL_STATUS_SET_ID}])`);
        const matnrs = getMatnrs(bestandanzeigen);
        // Wenn es keine Matnrs gibt, dann gibt es auch keine Bestände
        if (matnrs.length === 0) {
            return;
        }

        // Verhindern, dass der Bestand mehrfach angefragt wird
        aktualisiereLoadStatus(bestandanzeigen);
        const bestaende = await getBestaende(abholfiliale, matnrs);
        if (!bestaende) {
            return;
        }
        sendeBuchhandlungBestandanzeigeEvent(bestaende);
    } catch (error) {
        console.error(error);
    }
}

/**
 * @returns {string|boolean}
 */
function bestimmeAbholfiliale() {
    // Die Abholfiliale wird über das temporäre Cookie ausgelesen
    const sucheAbholfiliale = cookieWertAuslesen(SUCHE_ABHOLFILIALE_COOKIE_NAME);
    if (!sucheAbholfiliale) {
        // Wenn es kein temporäres Cookie gibt, dann wird das permanente Cookie ausgelesen
        return cookieWertAuslesen(ABHOLFILIALE_COOKIE_NAME);
    }
    return sucheAbholfiliale;
}

function aktualisiereLoadStatus(bestandanzeigen) {
    for (const element of bestandanzeigen) {
        element.setAttribute(AMPEL_STATUS_SET_ID, "");
    }
}

/**
 * @param {NodeList} bestandanzeigen
 * @returns {*[]}
 */
function getMatnrs(bestandanzeigen) {
    const matnrs = [];
    for (const element of bestandanzeigen) {
        const matnr = element.dataset["matnr"];
        if (typeof matnr !== "undefined") {
            matnrs.push(matnr);
        }
    }
    return matnrs;
}

/**
 * @param {String} abholfiliale
 * @param {Array} matnrs
 * @returns {Promise<any|void>}
 */
async function getBestaende(abholfiliale, matnrs) {
    try {
        const url = getRequestUrl(abholfiliale, matnrs);
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

/**
 * @param {String} abholfiliale
 * @param {Array} matnrs
 * @returns {module:url.URL}
 */
function getRequestUrl(abholfiliale, matnrs) {
    const url = new URL(window.location.origin);
    url.pathname = `/filialbestandsanzeige/api/rest/v1/${abholfiliale}/${matnrs.join(",")}`;
    return url;
}

export function sendeBuchhandlungBestandanzeigeEvent(bestaende) {
    const customEventBestand = new CustomEvent(BESTANDANZEIGE_CUSTOM_EVENT, {
        bubbles: true,
        detail: {
            ampelListe: bestaende
        }
    })
    document.dispatchEvent(customEventBestand)
}

export function sendeBuchhandlungBestandanzeigeRemoveEvent() {
    const customEventRemove = new CustomEvent(BESTANDANZEIGE_REMOVE_CUSTOM_EVENT, {
        bubbles: true,
    })
    document.dispatchEvent(customEventRemove)
}

window.addEventListener("beforeunload", beforeunloadCallback);

function beforeunloadCallback(event) {
    mutationObserver.disconnect();
}
